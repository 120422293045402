import React, { Suspense } from "react";
import { BrowserRouter } from 'react-router-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";
import ReactLoading from "react-loading";
/*import i18n from "i18next";
import {initReactI18next} from 'react-i18next';*/

const Navbar = React.lazy(() => import('./Navbar/Navbar'));
const Home = React.lazy(() => import('./Home/Home'));
const About = React.lazy(() => import('./About/About'));
const Contact = React.lazy(() => import('./Contact/Contact'));
const Service = React.lazy(() => import('./Service/Service'));

function App() {
  /*const userLang = localStorage.getItem("bfstridegroupLang") || navigator.language || navigator.userLanguage;
  i18n.use(initReactI18next).init({lng: userLang.split('-')[0]});*/
  return (
    <Suspense fallback={<div style={{top: '50%', position: 'absolute', left: '50%'}}><ReactLoading type="spinningBubbles" color="#0a58ca" /></div>}>
      <BrowserRouter>
        <Navbar></Navbar>
        <Switch>
          <React.Fragment>
            <main>
              <Route exact path="/">
                <Home></Home>
              </Route>
              <Route path="/about">
                <About></About>
              </Route>
              <Route path="/home" component={Home} />
              <Route path="/contact" component={Contact} />
              <Route path="/service" component={Service} />
              <Redirect to="/" />
            </main>
          </React.Fragment>
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
